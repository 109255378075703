import React from "react";
// Customizable Area Start
import {
  createTheme,
} from "@mui/material/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import {BrandList} from "../../../components/src/BrandList.web"
import {Typography } from "@mui/material";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import ImageCarousel from "../../../components/src/SliderComponent";
import LandingPageWebController,{Props} from "./LandingPageWebController.web";
import  {rightDots,leftDots} from "./assets"
// Customizable Area End

export default class LandingPage extends LandingPageWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <div
        data-test-id="landingPageContainer"
        style={{
          position: "relative",
          backgroundColor: "#EDE6DC",
          overflowX: "hidden",
        }}
      >
        <AppHeader />
        <div style={{ width: "100%", boxSizing: "border-box" }}>
          <FullWidthTabComponent
            categoriesOnHoverList={this.state.categoriesOnHoverList}
            getCategoriesOnHover={this.getCategoriesOnHover}
            categoriesList={this.state.categoriesArray}
          />

          <ImageCarousel landingPageData={this.state.landingPagesData} />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "-90px",
              marginBottom: "-100px",
              justifyContent: "space-between",
              position: "relative",
              zIndex: 1,
            }}
          >
            <img width={300} height={300} src={leftDots} alt="gvc" />

            <Typography
              style={{
                marginTop: "30px",
                fontFamily: "Big Caslon",
                fontSize: 30,
                fontWeight: "500",
                textAlign: "center",
                color: "#53503F",
                lineHeight: "36.27px",
              }}
            >
              All Genuine BRANDS
            </Typography>
            <img width={300} height={300} src={rightDots} alt="gvc" />
          </div>

          <div
            data-test-id="brandSection"
            style={{ position: "relative", zIndex: "99" }}
          >
            <BrandList
              brandsList={this.state?.brandsAndCataloguesList?.brands}
              categoryLable={"Brands"}
              shwoBrandComponent={true}
              ViewAllLink={"/"}
            />
            <BrandList
              brandsList={this.state?.brandsAndCataloguesList?.categories}
              categoryLable={"Watches"}
              shwoBrandComponent={false}
              ViewAllLink={"/"}
            />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const webStyle = {

};
// Customizable Area End
