import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landingPagesData:any;
  categoriesArray:any[];
  categoriesOnHoverList:any;
  brandsAndCataloguesList:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLandingPagesApiCallId:string="";
  getCategoriesListApiCallId:string="";
  getHoverCategoriesList:string="";
  getBrandsAndCatalouguesDataId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      // Customizable Area Start
      landingPagesData:[],
      categoriesArray:[],
      categoriesOnHoverList:{},
      brandsAndCataloguesList:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLandingPagesApiCallId !== null &&
      this.getLandingPagesApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && responseJson?.data){
        this.setState({
          landingPagesData:responseJson?.data
        })
      }

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    }if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoriesListApiCallId !== null &&
      this.getCategoriesListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && responseJson?.data){
        this.setState({
          categoriesArray:responseJson?.data
        })
      }
    } if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getHoverCategoriesList !== null &&
      this.getHoverCategoriesList ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({
          categoriesOnHoverList:responseJson
        })
      }
    }if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsAndCatalouguesDataId !== null &&
      this.getBrandsAndCatalouguesDataId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({
          brandsAndCataloguesList:responseJson
        })
      }
    }   
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
   this.getLandingPagesApiCallId= this.apiCall("",{
        method:"GET",
        endPoint:"/bx_block_content_management/landing_pages"
    })
    this.getCategoriesListApiCallId= this.apiCall("",{
      method:"GET",
      endPoint:"/bx_block_categories/categories"
  })
  this.getBrandsAndCatalouguesDataId= this.apiCall("",{
    method:"GET",
    endPoint:"/bx_block_catalogue/catalogues/new_arrivals"
})
    // Customizable Area End
  }
  getCategoriesOnHover=(categorID:string|number)=>{
    this.getHoverCategoriesList=this.apiCall("",{
      method:"GET",
      endPoint:`/bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
    })
  }
  apiCall = (token: any,payload:any) => {
    let {method,endPoint}=payload
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;

  };
  // Customizable Area End
}
