import React from 'react'
import {
  Button,
  Typography,
  TextField,
  InputAdornment,
  styled
} from "@mui/material";
import Badge from '@mui/material/Badge';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from "@mui/icons-material/Search";
import ProfilePopUp from './ProfilePopUp.web';
const chiragImg=require("./chirag.svg").default
const Logo=require("./Lgo.svg").default
const CartIcon=require("./socialmediaicons/cart.svg").default

const MyTextField = styled(TextField)(({ theme }) => ({
  width: "406px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "8px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));

const ResponsiveDiv = styled("div")(({ theme }) => ({
  width: "47vw",
  display: "flex",
  gap: "30px",
  marginLeft: "7.5rem",
  [theme.breakpoints.down('lg')]: {
    width: "320px",
    marginLeft: "5rem",
    gap: "20px",
  },
  [theme.breakpoints.down('md')]: {
    width: "280px", 
    marginLeft: "2.5rem",
    gap: "15px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
    marginLeft: "0",
    gap: "10px",
    justifyContent: "center",
  },
}));
const ResponsiveButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 20px",
  borderRadius: "8px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  [theme.breakpoints.down('sm')]: {
    fontSize: "14px",
    padding: "8px 16px",
  },
}));

export function AppHeader() {
  return (
    <div style={webStyle.container}>
      <div className="logoSearchBar" style={webStyle.logoDiv}>
        <div>
          <img
            style={{
              backgroundColor: "transparent",
              display: "block",
              width: "105px",
              height: "70.35px",
              top: "19.82px",
              left: "40px",
              padding: "0.53px 11.67px 12.06px 11.67px",
            }}
            src={Logo}
            alt="logo"
          />
        </div>
        <div className="searchBar">
          <MyTextField
            placeholder="Search for brands.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <ResponsiveDiv style={{width:"370px",display:"flex",gap:"30px",marginLeft:"7.5vw"}}>

      <ResponsiveButton
        style={{
          ...webStyle.sellwithus,
          backgroundColor: "#EDE6DC",
          color: "black",
        }}
        className="sellwithus"
      >
        sell with us
      </ResponsiveButton>

      <ResponsiveButton
        style={{
          ...webStyle.sellwithus,
          color: "white",
          backgroundColor: "#53503F",
          border:"none",
        }}
        className="sellwithus"
      >
        <img width={29} height={26} style={{marginRight:"5px"}} src={chiragImg} alt="vfv" />
        Make a wish
      </ResponsiveButton>
      </ResponsiveDiv>
      <div
        className="shortcuts"
            style={{ display: "flex",marginLeft:"2vw",marginRight:"65px", gap: "20px", alignItems: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: "#53503F",
              },
            }}
            badgeContent={1}
          >
             <img
            style={{
              width: "24px",
              height: "24px",
              top: "30.25px",
              left: "1203.5px",
              padding: "0.1px 0px 0.09px 0px",
            }}
            src={CartIcon}
            alt="logo"
          />
          </Badge>
          <Typography
            style={{
              fontFamily: "Avenir",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "center",
            }}
          >
            Cart
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FavoriteBorderIcon />
          <Typography
            style={{
              fontFamily: "Avenir",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "center",
            }}
          >
            Favorites
          </Typography>
        </div>
          <ProfilePopUp/>
        <KeyboardArrowDownIcon style={{ marginLeft: "-20px" }} />
      </div>
    </div>
  );
}

const webStyle = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 110,
    backgroundColor: "#EDE6DC",
    boxShadow: "0px 4px 8px 0px rgba(1, 1, 2, 0.19)"
  },
  text: {
    fontSize: 36,
    fontWeight: "600",
  },
  logoDiv: {
    display: "flex",
    marginLeft:"80px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 5px",
    gap: 20,
  },
  sellwithus: {
    fontSize:"16px",
    fontFamily:"Big Caslon",
    // width: "154px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid black",
  },
};
