import React, { useRef, useState, useEffect } from 'react';
import { Typography, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { SingleProductComponent } from './SingleProductLandingPage.web';
const rightArrow =require("./socialmediaicons/rightarrow.svg").default
const leftArrow =require("./socialmediaicons/leftarrow.svg").default

const Container = styled('div')({
  maxWidth: "100%",
  margin: "60px 0",
  boxSizing: "border-box",
});

const BrandHeader = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 50px",
});

const BrandName = styled(Typography)({
  fontFamily: "Avenir",
  fontSize: "30px",
  fontWeight: "800",
  color: "#53503F",
});

const CustomLink = styled(Link)({
  fontFamily: "Avenir",
  fontSize: "24px",
  fontWeight: "400",
  cursor: "pointer",
  textDecoration: "none",
  color: "#53503F",
});

const BrandContainer = styled('div')({
  width: "100%",
  marginTop: "40px",
  display: "flex",
  paddingLeft:"22px",
  boxSizing: "border-box",
  overflowX: "auto",
  overflowY: "hidden", 
  whiteSpace: "nowrap",
  "&::-webkit-scrollbar": {
    height: "0px", 
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc", 
    borderRadius: "0px",
  },
});

const BrandItem = styled('div')({
  height: "358px",
  width: "325px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: "10px solid #FFFFFF",
  backgroundColor: "#EDE6DC", 
  marginTop: "20px",
  marginLeft:"22px"
});

const BrandContent = styled('div')({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  borderRadius: "8px",
});

const ShopButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Big Caslon",
  width: "126px",
  height: "44px",
  padding: "10px 16px",
  borderRadius: "8px",
  color: "#EDE6DC",
  backgroundColor: "#53503F",
  "&:hover": {
    color: "#EDE6DC",
    backgroundColor: "#53503F",
  }
});

const CategoriesContainer = styled('div')({
  margin: "60px 0px",
  display: "flex",
  gap: "25px",
  padding: "0px 4px",
  boxSizing: "border-box",
  overflowX: "auto", 
  overflowY: "hidden",
  whiteSpace: "nowrap",
  paddingLeft:"50px",
  "&::-webkit-scrollbar": {
    height: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "0px",
  },
});

const ScrollButton = styled("button")({
  borderRadius: "50%",
  height: "48px",
  boxSizing:"border-box",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  width: "48px",
  background: "white",
  color: "black",
  fontWeight: "bold",
  cursor:"pointer",
  border: "none",
  "&:hover":{
    backgroundColor:"white"
  }
});

interface BrandListProps {
  shwoBrandComponent: boolean;
  categoryLable: string;
  ViewAllLink: string;
  brandsList: any
}

export function BrandList({
  brandsList,
  shwoBrandComponent,
  categoryLable,
  ViewAllLink,
}: BrandListProps) {
  const containerRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const [scrollButtonStates, setScrollButtonStates] = useState<{ [key: number]: { left: boolean, right: boolean } }>({});

  const updateButtonStates = (index: number) => {
    const container = containerRefs.current.get(index);
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setScrollButtonStates(prev => ({
        ...prev,
        [index]: {
          left: scrollLeft > 0,
          right: scrollLeft + clientWidth < scrollWidth,
        },
      }));
    }
  };

  const scrollLeft = (index: number) => {
    const container = containerRefs.current.get(index);
    if (container) {
      container.scrollBy({
        left: -300,
        behavior: "smooth",
      });
      updateButtonStates(index);
    }
  };

  const scrollRight = (index: number) => {
    const container = containerRefs.current.get(index);
    if (container) {
      container.scrollBy({
        left: 300,
        behavior: "smooth",
      });
      updateButtonStates(index);
    }
  };

  useEffect(() => {
    brandsList?.forEach((brand: any, index: number) => {
      if (brand?.catalogues && brand.catalogues.length > 4) {
        updateButtonStates(index + 1);
      }
    });
  }, [brandsList]);

  return shwoBrandComponent ? (
    <Container style={{marginBottom:brandsList?.length>4?"-60px":"60px"}}>
      <BrandHeader>
        <BrandName>{categoryLable}</BrandName>
        <CustomLink to={ViewAllLink}>View all</CustomLink>
      </BrandHeader>
      <BrandContainer
        ref={(el) => containerRefs.current.set(0, el)}
        onScroll={() => updateButtonStates(0)}
      >
        {brandsList?.map((item: any, index: number) => (
          <BrandItem key={index}>
            <BrandContent>
              <img
                height={67}
                width={126}
                src={item?.attributes?.image_url}
                alt=""
              />
              <ShopButton>Shop Now</ShopButton>
            </BrandContent>
          </BrandItem>
        ))}
      </BrandContainer>
      {brandsList?.length > 4 && (
        <div style={{ marginLeft:"10px",marginRight:"35px",paddingLeft:"40px",position: "relative", top: "-200px", width: "100%", display: "flex", justifyContent: "space-between" }}>
          <ScrollButton onClick={() => scrollLeft(0)} ><img height={20} width={15} src={rightArrow} alt="leftarrow" /></ScrollButton>
          <ScrollButton style={{marginRight:"68px"}} onClick={() => scrollRight(0)} ><img  height={20} width={15} src={leftArrow} alt="rightarrow" /></ScrollButton>
        </div>
      )}
    </Container>
  ) : (
    <>
      {brandsList?.map((brand: any, index: number) => (
        brand?.catalogues && (
          <Container key={index} style={{marginBottom:brand?.catalogues.length>4?"-90px":"60px"}}>
            <BrandHeader>
              <BrandName>{brand?.name}</BrandName>
              <CustomLink to={ViewAllLink}>View all</CustomLink>
            </BrandHeader>
            
              <>
                <CategoriesContainer
                  ref={(el) => containerRefs.current.set(index + 1, el)}
                  onScroll={() => updateButtonStates(index + 1)}
                >
                  {brand?.catalogues?.map((item: any, idx: number) => (
                    <SingleProductComponent key={idx} product={item} />
                  ))}
                </CategoriesContainer>
               {brand?.catalogues.length>4 &&( <div style={{ marginLeft:"10px",paddingLeft:"40px",marginRight:"35px",position: "relative", top: "-300px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <ScrollButton onClick={() => scrollLeft(index + 1)} disabled={!scrollButtonStates[index + 1]?.left}><img height={20} width={15} src={rightArrow} alt="leftarrow" /></ScrollButton>
                  <ScrollButton style={{marginRight:"68px"}} onClick={() => scrollRight(index + 1)} disabled={!scrollButtonStates[index + 1]?.right}><img  height={20} width={15} src={leftArrow} alt="rightarrow" /></ScrollButton>
                </div>)}
              </>
          </Container>
        )
      ))}
    </>
  );
}
