import React from 'react'
import { 
  Typography,
} from "@mui/material";
import {Link} from "react-router-dom"
const DownloadImg=require("./downloadIcon.svg").default
const LogoImg=require("./tresorlogo.svg").default;

const instagramImg=require("./socialmediaicons/instagram.svg").default
const twitterImg=require("./socialmediaicons/twitter.svg").default
const tiktokImg=require("./socialmediaicons/tiktok.svg").default
const xImg=require("./socialmediaicons/x.svg").default
const linkedinImg=require("./socialmediaicons/linkdin.svg").default


export function Footer() {
  return (
    <>
      <div style={{ width: "100%" }}>
            <div style={{backgroundColor: "#53503F", height:"442.5px"}}>
        <div
          className="footerLinks"
          style={{
            boxSizing: "border-box",
            width: "1196px",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#53503F",
            height: "282px",
            margin:"auto",
            position:"relative",
            top:"61px"
          }}
        >
          <img
            style={{
              width: "127.87px",
              height: "105.67px",
              padding: "0.65px 14.21px 14.68px 14.21px",
            }}
            src={LogoImg}
            alt="Logo"
          />
          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "Avenir",
                fontSize: "20px",
                fontWeight: "800",
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              ABOUT US
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
              }}
            >
              <Link style={{ color: "#EDE6DC", textDecoration: "none" }} to="/">
                Home
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Login/Register
              </Link>
            </div>
          </div>

          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "Avenir",
                fontSize: "20px",
                fontWeight: "800",
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              Categories
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
              }}
            >
              <Link style={{ color: "#EDE6DC", textDecoration: "none" }} to="/">
                Watches
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Bags
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Jewellery
              </Link>{" "}
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Footwear
              </Link>{" "}
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Accessories
              </Link>
            </div>
          </div>

          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "Avenir",
                fontSize: "20px",
                fontWeight: "800",
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              Support
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
              }}
            >
              <Link style={{ color: "#EDE6DC", textDecoration: "none" }} to="/">
                My Account
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                My Orders
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Contact Us
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Terms & Conditions
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Privacy Policy
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                FAQs
              </Link>
            </div>
          </div>
          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "Avenir",
                fontSize: "20px",
                fontWeight: "800",
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              Social media
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
              }}
            >
              <div style={{ color: "#EDE6DC", textDecoration: "none", display:"flex",gap:"6px" }}>
               <img  width={20} height={19} src={instagramImg} alt="instagram" />
               <img  width={20} height={19} src={twitterImg} alt={"icon"} />
               <img  width={20} height={19} src={linkedinImg} alt={"icon"} />
               <img  width={20} height={19} src={xImg} alt={"icon"} />
               <img  width={20} height={19} src={tiktokImg} alt={"icon"} />
              </div>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                <img style={{marginTop:"-19px"}} height={120} width={120} src={DownloadImg} alt="cdc" />
              </Link>
            </div>
          </div>
        </div>
        </div>
        

        <div
          style={{
            fontFamily: "Avenir",
            height: "61px",
            width: "100%",
            backgroundColor: "#EDE6DC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          © 2024 - All Rights Reserved by {"  "}
          <span
            style={{
              fontFamily: "Avenir",
              fontSize: "14px",
              fontWeight: 800,
              lineHeight: "21.87px",
              textAlign: "left",
              marginLeft:"10px"
            }}
          >
            TRÉSOR
          </span>
        </div>
      </div>
    </>
  );
}

const webStyle = {
    footerHeadings:{
        fontFamily: "Avenir",
        fontSize: "20px",
        fontWeight: "800",
        lineHeight: "30px",
        textAlign: "left",
      }
};
