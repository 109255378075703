import React, { useState, useRef, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export interface IProps {
  categoriesList:any[],
  getCategoriesOnHover:(categorID:string|number)=>void
  categoriesOnHoverList:any
}

// Styled Components
const FullWidthTabs = styled(Tabs)({
  boxSizing: "border-box",
  width: "100%",
  margin: "50px 30px 10px 30px",
  backgroundColor: "#EDE6DC",
  position: "relative",
  borderBottom: "0.5px solid #53503F",
});

const StyledTab = styled(Tab)(({ theme }) => ({
  position: "relative",
  transition: "color 0.2s",
  boxSizing: "border-box",
  fontFamily: "Avenir",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.25px",
  textAlign: "left",
}));

const Underline = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "2px",
  backgroundColor: "grey",
  transition: "width 0.2s, left 0.2s",
  zIndex: 1,
}));

const TabContentWrapper = styled(Box)({
  width: "120%",
  backgroundColor: "#EDE6DC",
  padding: "16px",
  marginLeft: "30px",
  position: "absolute",
  top: "100%",
  left: "-30px",
  zIndex: 80,
});

const TabContent = styled(Box)({
  display: "flex",
  gap: "30px",
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
});

const Header = styled(Typography)({
  fontFamily: "Avenir",
  fontSize: "14px",
  fontWeight: 400,
  margin: "5px 20px",
  width: "200px",
  lineHeight: "16px",
  letterSpacing: "0.01em",
  textAlign: "left",
  color: "#959595",
});

const CustomTypography = styled(Typography)({
  fontFamily: "Avenir",
  fontSize: "14px",
  fontWeight: 400,
  width: "200px",
  margin: "5px 20px",
  lineHeight: "16px",
  letterSpacing: "0.01em",
  textAlign: "left",
  color: "#53503F",
});

// Main Component
const FullWidthTabComponent: React.FC<IProps> = (props) => {
  const { categoriesList,getCategoriesOnHover,categoriesOnHoverList } = props;
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [activeTabId, setActiveTabId] = useState<string | number>(0);

  const [underlineStyle, setUnderlineStyle] = useState<{
    width: string;
    left: string;
  }>({ width: "0px", left: "0px" });
  const tabRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});

  // Function to generate content for each tab
  const getTabContent = (tab: string): JSX.Element | null => {
     return  tab!=="New Arrivals"? (
          <TabContent style={{width:"100%"}}>
            <Section>
              <Header>Men</Header>
              {
                categoriesOnHoverList["Men"]?.map((item:any,index:any)=>(
                  <CustomTypography>{item?.name}</CustomTypography>

                ))
              }
            </Section>
            <Section>
              <Header>Women</Header>
              {
                categoriesOnHoverList["Women"]?.map((item:any,index:any)=>(
                  <CustomTypography>{item?.name}</CustomTypography>

                ))
              }
            </Section>
            <Section>
              <Header>New Arrivals</Header>
              {
                categoriesOnHoverList["New Arrivals"]?.map((item:any,index:any)=>(
                  <CustomTypography>{item?.name}</CustomTypography>

                ))
              }
            </Section>
          </TabContent>
        ):null;

  };

  const handleMouseEnter = (tab: string,catId:string|number) => {
    const ref = tabRefs.current[tab];
    if (ref) {
      const tabRect = ref.getBoundingClientRect();
      const tabsRect = (
        ref.parentElement as HTMLElement
      ).getBoundingClientRect();
      setUnderlineStyle({
        width: `${tabRect.width}px`,
        left: `${tabRect.left - tabsRect.left}px`,
      });
    }
    setActiveTab(tab);
    setActiveTabId(catId)
    getCategoriesOnHover(catId)
  };

  const handleMouseLeave = () => {
    setActiveTab(null);
    setUnderlineStyle({ width: "0px", left: "0px" });
  };

  useEffect(() => {
    if (activeTab && tabRefs.current[activeTab]) {
      handleMouseEnter(activeTab,activeTabId);
    }
  }, [activeTab,activeTabId]);

  return (
    <Box
      onMouseLeave={handleMouseLeave}
      sx={{ maxWidth: "100%", width: "100%", boxSizing: "border-box" }}
      position="relative"
    >
      <FullWidthTabs
        value={false}
        aria-label="full width tabs example"
        variant="fullWidth"
      >
        <StyledTab
          key={"New Arrivals"}
          label={"New Arrivals"}
          onMouseEnter={() => handleMouseEnter("New Arrivals","0")}
          ref={(el) => {
            tabRefs.current["New Arrivals"] = el as HTMLButtonElement | null;
          }}
        />
        {categoriesList.map((tab: any) => (
          <StyledTab
            key={tab?.attributes?.name}
            label={tab?.attributes?.name}
            onMouseEnter={() => handleMouseEnter(tab?.attributes?.name,tab?.attributes?.id)}
            ref={(el) => {
              tabRefs.current[tab?.attributes?.name] =
                el as HTMLButtonElement | null;
            }}
          />
        ))}
        <Underline
          style={{ width: underlineStyle.width, left: underlineStyle.left }}
        />
      </FullWidthTabs>

      {activeTab && (
        <TabContentWrapper>{getTabContent(activeTab)}</TabContentWrapper>
      )}
    </Box>
  );
};

export default FullWidthTabComponent;
