import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { styled } from "@mui/system";
const rightArrow =require("./socialmediaicons/rightarrow.svg").default
const leftArrow =require("./socialmediaicons/leftarrow.svg").default
import {
  Typography,
} from "@mui/material";

const ImageCarouselContainer = styled("div")({
  ".carousel-image": {
    width: "100vw",
    height: "600px",
  },
  ".alice-carousel__wrapper":{
    height:"600px",
    marginTop:"50px",
    zIndex:"60"
  },
  ".dot": {
    width: 10,
    height: 10,
    margin: 5,
    borderRadius: "50%",
    backgroundColor: "#c7c0b7",
    opacity: "25px",
  },
  ".dot.active": {
    backgroundColor: "#53503F",
    width: "31.71px",
    height: "12px",
    gap: "0px",
    borderRadius: "25px",
  },
});
const ScrollButton = styled("button")({
  height: "48px",
  color: "black",
  boxSizing:"border-box",
  display:"flex",
  borderRadius: "50%",
  alignItems:"center",
  width: "48px",
  justifyContent:"center",
  background: "white",
  cursor:"pointer",
  fontWeight: "bold",
  border: "none",
  "&:hover":{
    backgroundColor:"white"
  }
});

const ImageCarousel = (props:any) => {
  const {landingPageData}=props
  const carouselRef = useRef<any>(null);
  
  const items = landingPageData.map((pageData:any, index:number) => (
   pageData?.attributes?.is_activated && <div style={{ display: "flex" }}>
      <div
        className="desc"
        style={{
          boxSizing: "border-box",
          width: "50%",
          height: "600px",
          padding: "140.5px 94.5px 140.5px 94.5px",
          gap: "50px",
          opacity: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#53503F",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "40px",
            alignItems: "center",
          }}
        >
          <span style={{
            fontFamily: "Big Caslon",
            fontSize: "45px",
            fontWeight: 500,
            lineHeight: "67.5px",
            letterSpacing: "-0.75px",
            textAlign: "center",
            color:"#EDE6DC"
            
          }}>{pageData?.attributes?.title}</span>
          <button
            style={{
              fontFamily: "Big Caslon",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              borderRadius: "8px",
              backgroundColor:"#EDE6DC",
              textAlign: "center",
              width: "126px",
              height: "44px",
              border:"none",
            }}
          >
            <Typography sx={{
              fontFamily:"Big Caslon",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "center",  
            }}>

            SHOP NOW
            </Typography>
          </button>
        </div>
      </div>

      <img
        style={{
          width: "50%",
          height: "600px",
          opacity: "0px",
        }}
        key={index}
        src={pageData?.attributes?.image}
        alt={`Carousel ${index}`}
        className="carousel-image"
      />
    </div>
  ));
  const slidePrev = () => {
    if (carouselRef?.current) {
      carouselRef.current?.slidePrev();
    }
  };
  const slideNext = () => {
    if (carouselRef?.current) {
      carouselRef.current.slideNext();
    }
  };
  return (
    <ImageCarouselContainer style={{position:"relative",marginTop:"-30px"}}>
      <div
        className="buttons"
        style={{
          display: "flex",
          width: "100%",
          position: "relative",
          top: "330px",
          justifyContent: "space-between",
          padding: "0px 10px",
          zIndex:"110"
        }}
      >
         <ScrollButton onClick={() => slidePrev()} ><img height={20} width={15} src={rightArrow} alt="leftarrow" /></ScrollButton>
         <ScrollButton style={{marginRight:"15px"}} onClick={() => slideNext()} ><img  height={20} width={15} src={leftArrow} alt="rightarrow" /></ScrollButton>
        {/* <div className="left" onClick={slidePrev}>{"<"}</div>
        <div className="right" style={{marginRight:"30px"}} onClick={slideNext}>{">"}</div> */}
      </div>
      <AliceCarousel
        items={items}
        autoPlay
        infinite
        animationDuration={1500}
        // animationType="fadeout"
        autoPlayInterval={1500}
        disableButtonsControls
        disableDotsControls={false}
        renderDotsItem={({ isActive }: any) => (
          <div className={`dot ${isActive ? "active" : ""}`}></div>
        )}
        ref={carouselRef}
      />
    </ImageCarouselContainer>
  );
};

export default ImageCarousel;